<template>
  <div>
  <section-head/>
    <router-view/>
  <section-footer/>
  </div>
</template>

<script>
import SectionFooter from '@/components/section/SectionFooter'
import SectionHead from '@/components/section/SectionHead'
export default {
  name: 'ViewsRoot',
  components: { SectionFooter, SectionHead }
}
</script>
